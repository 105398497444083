<template>
  <v-app id="inspire">
    <sidebar />
    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appName }}</v-toolbar-title>

    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="indigo" app>
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  components: {
    Sidebar: () => import("@/components/base/Sidebar")
  },
  props: {
    source: String
  },
  data: () => {
    return {
      appName: process.env.VUE_APP_NAME || "My APP"
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("toggleDrawerState", v);
      }
    }
  },
 
};
</script>
